import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    BadgeDollarSign,
    CircleAlert,
    CircleSlash,
    FileQuestion,
    Home,
    NotebookText,
    Vote,
    MessageCircle,
} from "lucide-react";
import useSound from "../../Services/SoundService";
import TelegramIcon from "../../assets/icons/telegram.svg";
import DiscordIcon from "../../assets/icons/discord.svg";

// Add X (Twitter) icon component
const XIcon = () => (
    <svg viewBox="0 0 24 24" aria-hidden="true" className="h-4 w-4" fill="currentColor">
        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
    </svg>
);

const AppFooter = () => {
    const links = [
        { href: "/", icon: Home, name: "Home" },
        { href: "/how-bidcoin-auctions-work", icon: FileQuestion, name: "How it works" },
        { href: "/auctions", icon: BadgeDollarSign, name: "Auctions" },
        { href: "/staking", icon: CircleSlash, name: "Staking" },
    ];

    const whitepaper = {
        href: "https://whitepaper.bidcoin.live",
        icon: NotebookText,
        name: "Whitepaper",
    };

    const navigate = useNavigate();
    const playNavigationSound = useSound("/sounds/tab_change.wav", 1);

    const handleNavigation = (path) => {
        playNavigationSound();
        window.scrollTo({ behavior: "smooth", top: 0 });
        navigate(path);
    };

    return (
        <footer className="">
            <div className="max-w-7xl mx-auto px-4 pt-2">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
                    {/* Brand Section */}
                    <div className="space-y-4">
                        <div className="flex items-center space-x-2">
                            {/* <img src="/favicon.ico" alt="Logo" className="h-8 w-8" /> */}
                            <span className="text-amber-500 font-medium">BIDCOIN</span>
                        </div>
                        <p className="text-zinc-400 text-sm">
                            Revolutionizing decentralized auctions with blockchain technology.
                        </p>
                    </div>

                    {/* Quick Links */}
                    <div className="space-y-4">
                        <h3 className="text-zinc-100 font-semibold">Quick Links</h3>
                        <div className="flex flex-col space-y-2">
                            {links.map((link) => (
                                <button
                                    key={link.href}
                                    onClick={() => handleNavigation(link.href)}
                                    className="text-left text-zinc-400 hover:text-white text-sm transition-colors"
                                >
                                    {link.name}
                                </button>
                            ))}
                            <a
                                href={whitepaper.href}
                                className="text-zinc-400 hover:text-white text-sm transition-colors"
                            >
                                {whitepaper.name}
                            </a>
                        </div>
                    </div>

                    {/* Community */}
                    <div className="space-y-4">
                        <h3 className="text-zinc-100 font-semibold">Community</h3>
                        <div className="flex flex-col space-y-2">
                            <span className="text-zinc-400/50 hover:text-zinc-400/50 text-sm transition-colors flex items-center space-x-2 cursor-not-allowed line-through">
                                <img
                                    src={DiscordIcon}
                                    alt="Discord"
                                    className="h-4 w-4 opacity-50"
                                />
                                <span>Discord (Coming Soon)</span>
                            </span>
                            <a
                                href="https://x.com/bidcoinnews"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-zinc-400 hover:text-white text-sm transition-colors flex items-center space-x-2"
                            >
                                <XIcon />
                                <span>X (Twitter)</span>
                            </a>
                            <a
                                href="https://t.me/BidCoinNews"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-zinc-400 hover:text-white text-sm transition-colors flex items-center space-x-2"
                            >
                                <img src={TelegramIcon} alt="Telegram" className="h-4 w-4" />
                                <span>Telegram</span>
                            </a>
                            <a
                                href="https://medium.com/@BidCoinNews"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-zinc-400 hover:text-white text-sm transition-colors flex items-center space-x-2"
                            >
                                <MessageCircle className="h-4 w-4" />
                                <span>Medium</span>
                            </a>
                        </div>
                    </div>

                    {/* Legal */}
                    <div className="space-y-4">
                        <h3 className="text-zinc-100 font-semibold">Legal</h3>
                        <div className="flex flex-col space-y-2">
                            <Link
                                to="/term-and-conditions"
                                className="text-zinc-400 hover:text-white text-sm transition-colors"
                            >
                                Terms & Conditions
                            </Link>
                            <Link
                                to="/privacy"
                                className="text-zinc-400 hover:text-white text-sm transition-colors"
                            >
                                Privacy & Compliance
                            </Link>
                            <Link
                                to="/legal-refund"
                                className="text-zinc-400 hover:text-white text-sm transition-colors"
                            >
                                Refund Policy
                            </Link>
                            <Link
                                to="/cookie-policy"
                                className="text-zinc-400 hover:text-white text-sm transition-colors"
                            >
                                Cookie Policy
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Copyright */}
                <div className="mt-2 pt-2 border-t border-zinc-800/50 text-center">
                    <p className="text-zinc-400 text-sm">
                        &copy; 2024 BidCoin. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default AppFooter;
