import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import truncateEthAddress from "truncate-eth-address";

const Breadcrumb = () => {
    const location = useLocation();

    const generateBreadcrumbs = () => {
        const paths = location.pathname.split("/").filter((path) => path);
        const breadcrumbs = [{ label: "BidCoin", path: "/" }];

        let currentPath = "";
        paths.forEach((path) => {
            currentPath += `/${path}`;

            // Handle special cases
            if (path === "auction-details") {
                breadcrumbs.push({ label: "Auction", path: "/auctions" });
                return;
            }
            if (path.match(/^0x[a-fA-F0-9]{40}$/)) {
                // This is an address - show truncated version
                const truncated = truncateEthAddress(path);
                breadcrumbs.push({ label: truncated, path: currentPath });
                return;
            }

            switch (path.toLowerCase()) {
                case "profile":
                    breadcrumbs.push({ label: "Profile", path: currentPath });
                    break;
                case "achievements":
                    breadcrumbs.push({ label: "Achievements", path: currentPath });
                    break;
                case "settings":
                    breadcrumbs.push({ label: "Settings", path: currentPath });
                    break;
                case "overview":
                    breadcrumbs.push({ label: "Overview", path: currentPath });
                    break;
                default:
                    // Capitalize each word in hyphenated strings
                    const label = path
                        .split("-")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ");
                    breadcrumbs.push({ label, path: currentPath });
            }
        });

        return breadcrumbs;
    };

    const breadcrumbs = generateBreadcrumbs();
    if (breadcrumbs.length === 1) return null;
    return (
        <nav className="flex items-center space-x-2 text-sm text-zinc-400 ">
            {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={crumb.path}>
                    {index > 0 && <ChevronRight className="w-4 h-4" />}
                    {index === breadcrumbs.length - 1 ? (
                        <span className="text-zinc-100 text-xs whitespace-nowrap">
                            {crumb.label}
                        </span>
                    ) : (
                        <Link
                            to={crumb.path}
                            className="hover:text-zinc-100 transition-colors text-xs whitespace-nowrap"
                        >
                            {crumb.label}
                        </Link>
                    )}
                </React.Fragment>
            ))}
        </nav>
    );
};

export default Breadcrumb;
